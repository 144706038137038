import { Component, Vue } from "vue-property-decorator";
import Model from "@/models/Model";
import { CREATE_SUB_ACCOUNT_AGENT } from '../../../../api/account';

@Component
default class AddSubAccount extends Vue {

    private displayShow = true
    private cookieData: any = Vue.$cookies.get("login")
    public role = ""
    private model: Model;
    private dataSubAccount: any = {}
    private subAccountMenus: any = []
    private password = {
        default: false,
        confirm: false
    }

    constructor() {
        super();
        this.model = new Model();
    }

    async mounted() {
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        this.role = this.cookieData.roles[0]
        if (this.cookieData.roles[0] == 'Company' || this.cookieData.roles[0] == 'Shareholder' || this.cookieData.roles[0] == 'Master Agent') {
            this.subAccountMenus.push({
                name: 'MENU_AGENT',
                permission: 'readonly'
            })
        }
        this.subAccountMenus.push({
            name: 'MENU_ACCOUNT',
            permission: 'readonly'
        }, {
            name: 'MENU_REPORT',
            permission: 'readonly'
        }, {
            name: 'MENU_CREDIT',
            permission: 'readonly'
        })
        this.addRouteName()
        loader.hide()
    }

    public toggleShowPassword(name: string) {
        if (name == 'default') {
            this.password.default = !this.password.default;
        }
        if (name == 'confirm') {
            this.password.confirm = !this.password.confirm;
        }
    }

    public onlyNumber(event: any) {
        const keyCode = (event.keyCode ? event.keyCode : event.which);
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
            event.preventDefault();
        }
    }

    public clsAlphaNoOnly(event: any) {  // Accept only alpha numerics, no special characters 
        const regex = new RegExp("^[a-zA-Z0-9]+$");
        const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (regex.test(str)) {
            return true;
        }

        event.preventDefault();
        return false;
    }

    public clsAlphaTHNoOnly(event: any) {  // Accept only alpha numerics, no special characters 
        const regex = new RegExp("^[ก-๏a-zA-Z0-9]+$");
        const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (regex.test(str)) {
            return true;
        }

        event.preventDefault();
        return false;
    }

    public changeInputValueInput(key: string, value: string) {
        this.displayShow = false
        this.dataSubAccount[key] = value
        this.displayShow = true
    }

    public changeInputValue(name: string, permission: string) {
        const data = {
            name: name,
            permission: permission
        }
        const index = this.subAccountMenus.findIndex((val: any) => val.name == name)
        if (index == -1) {
            this.subAccountMenus.push(data)
        } else {
            this.subAccountMenus[index].permission = permission
        }
        this.dataSubAccount['subAccountMenus'] = this.subAccountMenus
    }
    

    public async save(event: any) {
        event.preventDefault();
        this.dataSubAccount['subAccountMenus'] = this.subAccountMenus
        this.dataSubAccount['mainAccountId'] = this.cookieData.realUsername
        if (this.dataSubAccount['password'].length < 6) {
            this.$swal({
                text: this.$t('MANAGEMENT.PASSWORD_CHECK').toString(),
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            return;
        }

        this.$swal({
            title: this.$t('MANAGEMENT.WANT_ADD_SUB_ACC_DATA').toString(),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('CONFIRM').toString()}</span>`,
            cancelButtonColor: '#FF0000',
            cancelButtonText: `<span style='color: white;'>${this.$t('CANCEL').toString()}</span>`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                const result = await CREATE_SUB_ACCOUNT_AGENT(this.dataSubAccount)
                if (result.success) {
                    this.$swal({
                        text: this.$t('MANAGEMENT.ADD_SUB_ACC_SUCCESS').toString(),
                        icon: "success",
                        timer: 2000,
                        showConfirmButton: false,
                        timerProgressBar: true,
                        onOpen: () => {
                            this.$swal.isLoading()
                        },
                        onClose: () => {
                            window.location.replace('/member/sub-account');
                        }
                    })
                } else {
                    this.$swal({
                        text: result.error.message,
                        icon: "error",
                        confirmButtonColor: '#CFA137',
                        confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
                    });
                }
            }
        })
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

}
export default AddSubAccount